<template>
  <div class="artifacts one-component-detail-admin">
    <div class="title-component-admin artifacts">
      <el-row type="flex" align="middle" justify="space-between">
        <div>
          <span>Danh sách ({{ total }})</span>
          <synchronize-button
            :disabled="loading"
            :data="artifacts"
            type="artifact"
          />
          <import-excel-button
            size="small"
            :disabled="loading"
            :total="total"
            :columns="excelColumns"
            :type="'artifact'"
            :titles="importExportTitles"
            :active-tab="'artifact'"
            :detail="data"
            @reload="handleReloadData"
          />
          <export-excel-button
            size="small"
            :disabled="loading"
            :total="total"
            :columns="excelColumns"
            :type="'artifact'"
            :titles="importExportTitles"
            :detail="data"
            :active-tab="'artifact'"
          />
          <el-button
            type="primary"
            size="small"
            title="Làm mới"
            icon="el-icon-refresh-right"
            :disabled="loading"
            @click="handleReloadData"
          >Làm mới</el-button>
        </div>
        <div>
          <el-input
            v-model="searchText"
            suffix-icon="el-icon-search"
            placeholder="Tìm kiếm theo tên"
            size="small"
            style="width: 150px"
            @input="handleSearchArtifacts"
          ></el-input>
          <el-button
            type="primary"
            size="small"
            title="Thêm"
            :disabled="loading"
            @click="handleOpenPopup(null)"
          >Thêm</el-button>
        </div>
      </el-row>
    </div>
    <el-table v-loading="loading" :data="artifacts">
      <el-table-column label="Ưu tiên" prop="ordered" width="80">
      </el-table-column>
      <el-table-column width="60">
        <template slot-scope="scope">
          <span class="avatar-img">
            <img
              :src="
                scope.row.avatar && scope.row.avatar.url
                  ? scope.row.avatar.url
                  : imageDefaultUrl
              "
              alt="href"
            />
            <i
              v-if="scope.row.vrtour"
              class="fas fa-play-circle"
              title="Tour VR"
            ></i>
            <i v-if="scope.row.audio" class="fas fa-volume-up" title="Audio"></i>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Tên" prop="name" min-width="100">
        <template slot-scope="scope">
          <a href="javascript:;" class="text-primary text-limited" @click="handleOpenPopup(scope.row)">{{
            scope.row.name
          }}</a>
        </template>
      </el-table-column>
      <el-table-column label="Mô tả" prop="description">
        <template slot-scope="scope">
          <p class="text-limited">{{
            scope.row.description
          }}</p>
        </template>
      </el-table-column>
      <el-table-column
        label="Ngày tạo"
        prop="created_at"
        width="100"
      ></el-table-column>
      <el-table-column
        label="Thao tác"
        width="150"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-tooltip content="Chỉnh sửa thông tin" placement="top">
            <el-button
              icon="el-icon-edit"
              type="primary"
              circle
              size="small"
              @click="handleOpenPopup(scope.row)"
            ></el-button>
          </el-tooltip>
          <el-tooltip content="Xóa" placement="top">
            <el-button
              icon="el-icon-delete"
              circle
              type="danger"
              size="small"
              @click="handleDeleteArtifact(scope.row)"
            ></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" align="middle" justify="end" class="my-3">
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 20, 30]"
        :page-size.sync="limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleChangeSize"
        @current-change="handleChangePage"
      ></el-pagination>
    </el-row>
    <artifact-popup
      v-if="formVisible"
      :visible="formVisible"
      :artifact="selectedArtifact"
      :object="data"
      :type="type"
      @close="formVisible = false"
      @reload="getArtifacts"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters, mapActions } from 'vuex'
import { getArtifacts, deleteArtifact } from '@/services/artifact'
import ArtifactPopup from '../popups/Artifact.vue'
import SynchronizeButton from '../buttons/Synchronize'
import ImportExcelButton from '../buttons/ImportExcel.vue'
import ExportExcelButton from '../buttons/ExportExcel.vue'

import TYPE from '@/data/type'

export default {
  name: 'Artifacts',
  components: {
    ArtifactPopup,
    SynchronizeButton,
    ImportExcelButton,
    ExportExcelButton
  },
  props: {
    data: Object,
    type: String
  },
  data() {
    return {
      loading: true,
      artifacts: [],
      formVisible: false,
      selectedArtifact: null,
      imageDefaultUrl: '/default-image.jpeg',
      searchText: '',
      limit: 10,
      total: 0,
      page: 1
    }
  },
  computed: {
    ...mapGetters(['language']),
    placeType() {
      return TYPE.PLACES.find((p) => p.value === this.type)
    },
    params() {
      const p = {
        locale: this.language,
        object_type: `${this.type}-item`,
        order: '-ordered',
        search_text: this.searchText,
        page: this.page,
        per_page: this.limit
      }
      return {
        ...p,
        place_id: this.data.id
      }
    },
    excelColumns() {
      const eColumns = ['id', 'name', 'ordered', 'vrtour', 'description', 'content']
      return (eColumns || []).map((c) =>
        TYPE.EXCEL_COLUMNS.find((column) => c === column.key)
      )
    },
    importExportTitles() {
      return [{ name: 'Danh sách', value: 'artifact' }]
    }
  },
  beforeMount() {
    this.getArtifacts()
  },
  methods: {
    ...mapActions('artifact', ['updateReloadArtifactGroup']),
    getArtifacts() {
      this.loading = true
      getArtifacts(this.params).then((response) => {
        this.artifacts = response.result.map((artifact) => ({
          ...artifact,
          vrtour: (artifact.translation ? artifact.translation.vrtour : artifact.vrtour) || ''
        }))
        this.total = response.total
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    handleSearchArtifacts(text) {
      this.searchText = text
      this.page = 1
      this.getArtifacts()
    },

    handleOpenPopup(artifact) {
      this.selectedArtifact = artifact
      this.formVisible = true
    },

    handleDeleteArtifact(artifact) {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        this.loading = true
        deleteArtifact({ id: artifact.id })
          .then(() => {
            this.artifacts = this.artifacts.filter((a) => a.id !== artifact.id)
            this.loading = false
            this.updateReloadArtifactGroup(dayjs().unix())
          })
          .catch(() => {
            this.loading = false
            this.$notify.error({
              title: 'Thông báo',
              message: 'Không thể xóa!'
            })
          })
      })
    },

    handleChangePage(page) {
      this.artifacts = []
      this.page = page
      this.getArtifacts()
    },

    handleChangeSize(size) {
      this.limit = size
      this.page = 1
      this.getArtifacts()
    },

    handleReloadData() {
      this.searchText = ''
      this.page = 1
      this.getArtifacts()
    }
  }
}
</script>

<style lang="scss" scoped>
.artifacts {
  .avatar-img {
    position: relative;
    img {
      width: 48px !important;
      height: 48px !important;
      object-fit: cover;
      border-radius: 50%;
    }
    svg {
      position: absolute;
    }
    .fa-volume-up {
      right: -2px;
      bottom: 34px;
      color: #409eff;
      font-size: 18px;
    }
    .fa-play-circle {
      right: -2px;
      top: -2px;
      font-size: 20px;
      color: #67c23a;
    }
  }
}
</style>
