import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getFoods(params) {
  return request({
    url: `${ENDPOINT.DISH}`,
    method: 'get',
    params
  })
}

export function createFood(data) {
  return request({
    url: `${ENDPOINT.DISH}`,
    method: 'post',
    data
  })
}

export function updateFood(data) {
  return request({
    url: `${ENDPOINT.DISH}`,
    method: 'patch',
    data
  })
}

export function deleteFood(params) {
  return request({
    url: `${ENDPOINT.DISH}`,
    method: 'delete',
    params
  })
}

export function getFoodGroups(params) {
  return request({
    url: ENDPOINT.DISH_GROUP,
    method: 'get',
    params
  })
}

export function createFoodGroup(data) {
  return request({
    url: ENDPOINT.DISH_GROUP,
    method: 'post',
    data
  })
}

export function updateFoodGroup(data) {
  return request({
    url: ENDPOINT.DISH_GROUP,
    method: 'patch',
    data
  })
}

export function deleteFoodGroup(id) {
  return request({
    url: `${ENDPOINT.DISH_GROUP}?id=${id}`,
    method: 'delete'
  })
}
