<template>
  <el-dialog
    top="80px"
    title="Danh sách các dịch vụ nổi bật"
    class="services-popup"
    width="780px"
    :visible="visible"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div class="services-popup__content">
      <div v-if="!services.length" class="empty">
        <p>Hiện tại chưa có thông tin các dịch vụ tiện ích</p>
        <p>Vui lòng tạo thêm thông tin.</p>
      </div>
      <div v-else>
        <el-row
          v-for="group in serviceGroups"
          :key="group.index"
          type="flex"
          justify="space-between"
          class="mb-3"
        >
          <el-card v-for="service in group" :key="service.id">
            <img :src="service.avatar_url" alt="href" />
            <p class="mb-0">
              <b>{{ service.name }}</b>
            </p>
            <el-checkbox
              v-model="service.checked"
              size="medium"
              @change="(value) => (service.checked = value)"
            ></el-checkbox>
          </el-card>
        </el-row>
      </div>
    </div>
    <el-row slot="footer" class="footer pt-3" type="flex" justify="space-between" align="center">
      <el-checkbox v-model="selectAll" size="medium" @change="handleSelectAll">Chọn tất cả</el-checkbox>
      <el-button type="primary" @click="handleCompleted">Hoàn tất</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  name: 'ServicesPopup',
  props: {
    visible: Boolean,
    services: Array,
    selectedServices: Array
  },
  data() {
    return {
      serviceGroups: [],
      selectAll: false
    }
  },
  computed: {
    service_ids() {
      let ids = []
      this.serviceGroups.forEach((g) => {
        ids = [...ids, ...g.filter((s) => s.checked).map((s) => s.id)]
      })
      return ids
    }
  },
  beforeMount() {
    const groups = []
    const services = this.services.map((s) => ({
      ...s,
      checked: this.selectedServices.includes(s.id)
    }))
    for (let i = 0; i < services.length; i += 4) {
      if (i + 4 <= services.length) {
        groups.push(services.slice(i, i + 4))
      } else {
        groups.push(services.slice(i, services.length))
      }
    }
    this.serviceGroups = groups
  },
  methods: {
    handleSelectAll() {
      if (this.selectAll) {
        this.serviceGroups = this.serviceGroups.map((g) =>
          g.map((s) => ({ ...s, checked: true }))
        )
      } else {
        this.serviceGroups = this.serviceGroups.map((g) =>
          g.map((s) => ({ ...s, checked: false }))
        )
      }
    },
    handleCompleted() {
      this.$emit('update', this.service_ids)
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
.services-popup {
  .footer {
    border-top: 1px solid #f5f5f5;
  }
  &__content {
    .empty {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      text-align: center;
    }
    .el-card {
      width: 170px;
      text-align: center;
      position: relative;
      img {
        width: 48px;
        height: 48px;
      }
      .el-checkbox {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
}
</style>
<style lang="scss">
.services-popup {
  .el-dialog {
    &__body {
      overflow: auto;
    }
  }
  .el-card__body {
    padding: 10px;
  }
}
</style>
