<template>
  <el-form ref="form" :model="data" :rules="rules" label-position="top">
    <el-form-item :label="info.label" :prop="info.key">
      <el-input v-model="data[info.key]" :placeholder="info.placeholder" clearable @input="inputValue"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'InputType',
  props: {
    info: Object,
    data: Object
  },
  data() {
    return {}
  },
  computed: {
    rules() {
      const r = {}
      r[this.info.key] = [
        {
          required: this.info.required,
          message: `${this.info.label} không được để trống.`,
          trigger: ['blur', 'change']
        }
      ]
      return r
    }
  },
  methods: {
    handleValidate() {
      let valid = false
      this.$refs.form.validate((v) => {
        valid = v
      })
      return valid
    },
    inputValue(v) {
      if (this.info.key === 'name') {
        this.data.qr_code = v
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
