<template>
  <div class="setting-price one-component-detail-admin">
    <div class="title-component-admin">Giá</div>
    <div class="mx-3">
      <el-row type="flex" class="py-3">
        <el-radio
          v-model="data.price_info.free"
          :label="true"
        >Miễn phí</el-radio>
        <el-radio
          v-model="data.price_info.free"
          :label="false"
        >Khoảng giá</el-radio>
      </el-row>
      <el-form
        v-if="!data.price_info.free"
        :model="data.price_info"
        inline
        label-position="left"
      >
        <el-row type="flex">
          <el-form-item label="Từ">
            <el-input v-model.number="data.price_info.minimum"></el-input>
          </el-form-item>
          <el-form-item label="Đến">
            <el-input v-model.number="data.price_info.maximum"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Price',
  props: {
    data: Object
  }
}
</script>

<style lang="scss" scoped></style>
