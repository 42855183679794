<template>
  <el-form :model="form" label-position="top">
    <el-form-item label="Gói tham quan thực tế">
      <el-select
        v-model="form.tourPackage"
        filterable
        clearable
        remote
        placeholder="Chọn gói thực tế"
        :disabled="callingAPI || loading"
        :remote-method="(text) => handleSearchPackages(text, 'tour')"
        :loading="tourSearching"
        @change="handleUpdate('tour', true)"
      >
        <el-option v-for="p in tourPackages" :key="p.id" :label="p.summary" :value="p.id"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Gói tham quan thực tế ảo VR3D">
      <el-select
        v-model="form.vrtourPackage"
        filterable
        clearable
        remote
        placeholder="Chọn gói thực tế ảo"
        :disabled="callingAPI || loading"
        :remote-method="(text) => handleSearchPackages(text, 'vr_tour')"
        :loading="vrtourSearching"
        @change="handleUpdate('vr_tour', true)"
      >
        <el-option v-for="p in vrtourPackages" :key="p.id" :label="p.summary" :value="p.id"></el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { getPackages, updatePackage } from '@/services/package'
import TYPE from '@/data/type'

export default {
  name: 'SelectPackages',
  props: {
    type: String,
    object: Object,
    autoUpdate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tourSearching: false,
      vrtourSearching: false,
      callingAPI: false,
      loading: true,
      form: {
        tourPackage: '',
        vrtourPackage: ''
      },
      tourPackages: [],
      vrtourPackages: [],
      objectPackages: []
    }
  },
  computed: {
    ...mapGetters(['language']),
    placeType() {
      return TYPE.PLACES.find((p) => p.value === this.type)
    },
    obTourP () {
      return this.objectPackages.find((p) => p.package_type === 'tour')
    },
    obVrtourP () {
      return this.objectPackages.find((p) => p.package_type === 'vr_tour')
    },
    tourP () {
      return this.obTourP ? this.tourPackages.find((p) => p.id === this.obTourP.id) : null
    },
    vrtourP () {
      return this.obVrtourP ? this.vrtourPackages.find((p) => p.id === this.obVrtourP.id) : null
    },
    selectedTour () {
      return this.tourPackages.find((p) => p.id === this.form.tourPackage)
    },
    selectedVrtour () {
      return this.vrtourPackages.find((p) => p.id === this.form.vrtourPackage)
    }
  },
  beforeMount () {
    this.getObjectPackages()
  },
  methods: {
    handleSearchPackages(text, type) {
      setTimeout(() => {
        this.getPackagesByType(text, type)
      }, 500)
    },
    async getPackagesByType (text = '', type = 'tour', loading = false) {
      this.tourSearching = type === 'tour'
      this.vrtourSearching = type === 'vr_tour'
      const params = {
        locale: this.language,
        per_page: 10,
        page: 1,
        package_type: type,
        search_text: text
      }
      getPackages(params).then((response) => {
        if (type === 'tour') {
          this.tourPackages = response.result
        } else if (type === 'vr_tour') {
          this.vrtourPackages = response.result
        }
        if (loading) {
          this.form.tourPackage = this.tourP ? this.tourP.id : ''
          this.form.vrtourPackage = this.vrtourP ? this.vrtourP.id : ''
        }
        this.tourSearching = false
        this.vrtourSearching = false
      }).catch(() => {
        this.tourSearching = false
        this.vrtourSearching = false
      })
    },

    async getObjectPackages () {
      this.loading = true
      const params = {
        locale: this.language,
        per_page: 10,
        page: 1,
        place_id: this.object.id
      }
      getPackages(params).then(async (response) => {
        this.objectPackages = response.result
        const requests = [this.getPackagesByType('', 'tour', true), this.getPackagesByType('', 'vr_tour', true)]
        Promise.all(requests).then(() => {
          this.loading = false
        })
      }).catch(() => {
        this.loading = false
      })
    },

    getSelectedPackages () {
      const selectedPackages = []
      if (this.selectedTour) {
        selectedPackages.push(this.selectedTour)
      }
      if (this.selectedVrtour) {
        selectedPackages.push(this.selectedVrtour)
      }
      return selectedPackages
    },

    async handleUpdateAll () {
      await this.handleUpdate('tour', true)
      await this.handleUpdate('vr_tour', true)
    },

    async handleUpdate (type, isUpdate = false) {
      if (this.autoUpdate || isUpdate) {
        let newData = null
        let oldData = null
        if (type === 'tour') {
          if (this.tourP) {
            oldData = this.tourP
          }
          if (this.selectedTour) {
            newData = this.selectedTour
          }
        } else if (type === 'vr_tour') {
          if (this.vrtourP) {
            oldData = this.vrtourP
          }
          if (this.selectedVrtour) {
            newData = this.selectedVrtour
          }
        }
        if (oldData) {
          await this.updatePackage(oldData, 'remove')
        }
        if (newData) {
          await this.updatePackage(newData, 'select')
        }
      }
    },

    async updatePackage (p, type) {
      let data = {
        id: p.id,
        start_time: p.start_time,
        end_time: p.end_time
      }
      const placeIds = p.places.map((t) => t.id)
      data.place_ids = type === 'remove' ? placeIds.filter((t) => t !== this.object.id) : [...new Set([...placeIds, this.object.id])]
      data = {
        ...data,
        locale: this.language,
        start_time: `${data.start_time.split('/').reverse().join('-')}T00:00:00`,
        end_time: `${data.end_time.split('/').reverse().join('-')}T00:00:00`
      }
      updatePackage(data)
    }
  }
}
</script>
<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
