<template>
  <div class="background-group one-component-detail-admin">
    <div class="title-component-admin">Background</div>
    <el-row :gutter="12" class="p-3">
      <el-col v-for="item in items" :key="item.key" :lg="6" :sm="12" :span="24">
        <div v-loading="item.loading" class="background-group__item">
          <p class="title mt-0 mb-2 text-bg">{{ item.title }}</p>
          <div v-if="data[item.objectKey]">
            <audio
              v-if="item.fileType === 'audio'"
              controls
              style="width: 100%;"
            >
              <source :src="data[item.objectKey].url" />
            </audio>
            <img
              v-if="item.fileType === 'image'"
              :src="data[item.objectKey].url"
              alt="href"
              style="height: 120px"
            />
            <video
              v-if="item.fileType === 'video'"
              width="100%"
              controls
              style="height: 120px"
            >
              <source :src="data[item.objectKey].url" type="video/mp4" />
            </video>
          </div>
          <div v-else>
            <el-row
              v-if="item.fileType === 'audio'"
              type="flex"
              justify="center"
            >
              <p>Chưa có audio</p>
            </el-row>
            <img
              v-if="item.fileType === 'image'"
              src="@/assets/images/default-image.jpeg"
              alt="href"
              style="height: 120px"
            />
            <img
              v-if="item.fileType === 'video'"
              src="@/assets/images/thumbnail-overlay.png"
              alt="href"
              style="height: 120px"
            />
          </div>
          <div class="buttons">
            <upload-file
              :type="type"
              :file-type="item.fileType"
              :object-type="objectType"
              @uploading="item.loading = true"
              @uploaded="item.loading = false"
              @update="(file) => handleUpdateFile(item, file)"
              @delete="deleteOldFile(item)"
            >
              <el-button size="mini" type="primary" plain>
                {{ !data[item.objectKey] ? "Tải" : "Đôi" }} {{ item.title }}
              </el-button>
            </upload-file>
            <el-button
              v-if="data[item.objectKey]"
              size="mini"
              type="danger"
              class="ml-2"
              plain
              @click.native="deleteFile(item)"
            >
              Xoá
            </el-button>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UploadFile from '../items/UploadFile'
import { deleteStorages } from '@/services/storage'

export default {
  name: 'BackgroundGroup',
  components: { UploadFile },
  props: {
    data: Object,
    type: String
  },
  data() {
    return {
      callingAPI: false,
      items: [
        {
          title: 'Logo',
          fileType: 'image',
          key: 'logo_id',
          objectKey: 'logo',
          loading: false
        },
        {
          title: 'Ảnh',
          fileType: 'image',
          key: 'background_image_id',
          objectKey: 'background_image',
          loading: false
        },
        {
          title: 'Video',
          fileType: 'video',
          key: 'background_video_id',
          objectKey: 'background_video',
          loading: false
        },
        {
          title: 'Audio',
          fileType: 'audio',
          key: 'background_audio_id',
          objectKey: 'background_audio',
          loading: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    objectType() {
      if (['artifact', 'dish', 'dish_group'].includes(this.type)) {
        return this.type
      }
      return 'place'
    }
  },
  methods: {
    async deleteOldFile(item) {
      if (this.data[item.objectKey]) {
        await deleteStorages({
          file_type: item.fileType,
          object_type: this.objectType,
          ids: this.data[item.objectKey].id
        })
          .then(() => {
            this.data[item.objectKey] = null
            this.data[item.key] = null
          })
          .catch(() => {})
      } else {
        this.data[item.objectKey] = null
        this.data[item.key] = null
      }
      await this.$emit('update', false)
    },
    deleteFile(item) {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        this.deleteOldFile(item)
      })
    },
    handleUpdateFile(item, file) {
      this.data[item.objectKey] = file
      this.data[item.key] = file.id
      this.$emit('update', false)
      item.loading = false
    }
  }
}
</script>

<style lang="scss">
.background-group {
  &__item {
    position: relative;
    height: auto;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 10px;
    }
    &:hover {
      .buttons {
        opacity: 1;
        transition: all 0.5s;
      }
    }
    .buttons {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      top: 90%;
      opacity: 0;
    }
  }
}
</style>
