<template>
  <div class="campaign-type one-component-detail-admin">
    <div class="title-component-admin">Thời gian diễn ra</div>
    <el-form :model="data" class="p-3">
      <el-form-item>
        <el-date-picker
          v-model="dates"
          type="daterange"
          range-separator="-"
          start-placeholder="Ngày bắt đầu"
          end-placeholder="Ngày kết thúc"
          format="dd-MM-yyyy"
          value-format="yyyy-MM-ddT00:00:00"
          class="w-100"
          :clearable="false"
          @change="changeDates"
        ></el-date-picker>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Times',
  props: {
    data: Object,
    type: String
  },
  data() {
    return {
      dates: [
        this.data.place_duration && this.data.place_duration._started_at ? `${this.data.place_duration._started_at.split('/').join('-')}T00:00:00` : '',
        this.data.place_duration && this.data.place_duration._ended_at ? `${this.data.place_duration._ended_at.split('/').join('-')}T00:00:00` : ''
      ]
    }
  },
  computed: {
    ...mapGetters(['constant'])
  },
  methods: {
    changeDates() {
      this.data.started_at = this.dates ? this.dates[0] : null
      this.data.ended_at = this.dates ? this.dates[1] : null
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
