import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getAddresses(text) {
  return request({
    url: ENDPOINT.GOONG_AUTOCOMPLETE,
    method: 'get',
    params: {
      query: text
    }
  })
}

export function getAddress(id) {
  return request({
    url: ENDPOINT.GOONG_DETAIL,
    method: 'get',
    params: {
      place_id: id
    }
  })
}
