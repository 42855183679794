<template>
  <div class="country one-component-detail-admin">
    <div class="title-component-admin">Đất nước</div>
    <el-form :model="form" class="p-3">
      <el-form-item label="">
        <el-select v-model="form.phone"></el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'Country',
  data() {
    return {
      form: {}
    }
  }
}
</script>

<style lang="scss" scoped></style>
