<template>
  <div class="upload-file">
    <el-upload
      class="file-uploader"
      action
      :drag="drag"
      :multiple="multiple"
      :accept="`${fileType}/*`"
      :show-file-list="false"
      :http-request="uploadFiles"
    >
      <slot />
    </el-upload>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { createStorage } from '@/services/storage'
export default {
  name: 'UploadFile',
  props: {
    type: String,
    objectId: String,
    fileType: String,
    objectType: String,
    multiple: {
      type: Boolean,
      default: false
    },
    drag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      callingAPI: false,
      fileInfo: {}
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    params() {
      return {
        file: this.fileInfo.file,
        objectId: this.objectId || '',
        objectType: this.objectType,
        fileType: this.fileType,
        locale: this.language
      }
    }
  },
  methods: {
    async uploadFiles(fileInfo) {
      this.callingAPI = true
      await this.$emit('uploading')
      await this.$emit('delete')
      this.fileInfo = fileInfo
      createStorage(this.params).then((res) => {
        this.callingAPI = false
        this.$emit('update', res.data)
      }).catch(() => {
        this.callingAPI = false
        this.$emit('uploaded')
        this.$notify.error({
          title: 'Thông báo',
          message: `Không thể upload ${this.fileType}: ${fileInfo.file.name}`
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  &__content {
    text-align: center;
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      border: 1px solid #f5f5f5;
    }
  }
}
</style>
<style lang="scss">
.upload-file {
  display: flex;
  justify-content: center;
  text-align: center;
  .el-upload-dragger {
    width: 100%;
    height: 120px;
    i {
      margin: 15px;
    }
  }
}
</style>
