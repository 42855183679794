<template>
  <div class="display one-component-detail-admin">
    <div class="title-component-admin">Hiển thị - Ưu tiên</div>
    <el-form :model="data" class="p-3">
      <el-row type="flex" align="middle" justify="space-between">
        <el-row type="flex" align="middle">
          <el-radio v-model="data.status" label="public">Public</el-radio>
          <el-radio v-model="data.status" label="private">Private</el-radio>
        </el-row>
        <el-switch
          v-model="data.highlighted"
          :disabled="data.status === 'private'"
          title="Độ ưu tiên"
          active-color="#13ce66"
        >
        </el-switch>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'Display',
  props: {
    data: Object
  },
  watch: {
    'data.status': 'statusChange'
  },
  methods: {
    statusChange() {
      if (this.data.status === 'private') {
        this.data.highlighted = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
