<template>
  <div class="date-of-birth one-component-detail-admin">
    <div class="title-component-admin">Ngày sinh - Ngày mất</div>
    <el-form :model="data" class="p-3">
      <el-row type="flex" justify="space-between" :gutter="12">
        <el-col :lg="12">
          <el-form-item prop="dob">
            <el-date-picker
              v-model="data.dob"
              placeholder="Ngày sinh"
              format="dd-MM-yyyy"
              class="w-100"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :lg="12">
          <el-form-item prop="dod">
            <el-date-picker
              v-model="data.dod"
              placeholder="Ngày mất"
              format="dd-MM-yyyy"
              class="w-100"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'DateOfBirth',
  props: {
    data: Object
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.date-of-birth {
  .el-date-editor {
    width: 100% !important;
  }
}
</style>
