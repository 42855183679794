<template>
  <div class="setting-price one-component-detail-admin">
    <div class="title-component-admin">Thông tin liên hệ</div>
    <el-form :model="data" class="p-3">
      <el-form-item label="Số điện thoại">
        <el-input v-model="data.contact_info.phone" suffix-icon="el-icon-phone"></el-input>
      </el-form-item>
      <el-form-item label="Địa chỉ Email">
        <el-input v-model="data.contact_info.email" suffix-icon="el-icon-message"></el-input>
      </el-form-item>
      <el-form-item label="Link Booking">
        <el-input v-model="data.booking" suffix-icon="el-icon-link"></el-input>
      </el-form-item>
      <el-form-item label="Website">
        <el-input v-model="data.contact_info.website" suffix-icon="el-icon-link"></el-input>
      </el-form-item>
      <el-form-item label="Facebook">
        <el-input v-model="data.facebook_url" suffix-icon="el-icon-link"></el-input>
      </el-form-item>
      <el-form-item label="Zalo">
        <el-input v-model="data.zalo_url" suffix-icon="el-icon-link"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  props: {
    data: Object,
    type: String
  }
}
</script>

<style lang="scss" scoped></style>
