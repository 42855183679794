<template>
  <el-row v-loading="loading || callingAPI" class="shared-show shared-detail">
    <el-row
      class="title mb-2"
      type="flex"
      align="middle"
      justify="space-between"
    >
      <h3 class="m-0">
        CHỈNH SỬA THÔNG TIN: {{ formData.name ? formData.name.toUpperCase() : '' }}
      </h3>
      <shared-actions
        :data="formData"
        :type="type"
        :actions="actions"
        :table-data="[]"
        :router-data="data"
        @showQR="() => qrVisible = true"
        @reloadPage="() => $router.push({ name: data.name })"
      />
    </el-row>
    <el-col v-if="!loading" :lg="15" class="shared-show__left">
      <div class="setting-price one-component-detail-admin">
        <div class="title-component-admin">
          <el-row type="flex" align="center" justify="space-between">
            <span>Thông tin</span>
            <span v-if="formData.updated_at">Cập nhật lần cuối: {{ formData.updated_at }}</span>
          </el-row>
        </div>
        <div class="p-3">
          <div v-for="(i, index) in formInputs" :key="`${index}-${i.value}`">
            <input-type
              v-if="i.type === 'input'"
              :ref="i.ref"
              :info="i"
              :data="formData"
            />
            <textarea-type
              v-if="i.type === 'textarea'"
              :info="i"
              :data="formData"
            />
            <number-type
              v-if="i.type === 'number'"
              :info="i"
              :data="formData"
            />
            <editor-type
              v-if="i.type === 'editor'"
              :info="i"
              :data="formData"
              @update="updateDetailInfo"
            />
          </div>
        </div>
      </div>
      <div v-for="card in lefts" :key="card">
        <list-facility
          v-if="card === 'facility'"
          :data="formData"
          :type="type"
        />
        <services v-if="card === 'services'" :data="formData" :type="type" />
        <schedule v-if="card === 'schedule'" :data="formData" :type="type" />
        <artifacts v-if="card === 'artifacts'" :data="formData" :type="type" />
        <room-types v-if="card === 'room_type'" :data="formData" :type="type" />
        <artifact-groups
          v-if="card === 'artifact_groups'"
          :data="formData"
          :type="type"
        />
        <groups v-if="card === 'groups'" :data="formData" :type="type" />
        <rooms v-if="card === 'rooms'" :data="formData" :type="type" />
        <banner v-if="card === 'banner'" :data="formData" :type="type" />
        <foods v-if="card === 'foods'" :data="formData" :type="type" />
        <food-groups
          v-if="card === 'food_groups'"
          :data="formData"
          :type="type"
        />
        <background-group
          v-if="card === 'background'"
          :data="formData"
          :type="type"
          @update="updateDetailInfo"
        />
      </div>
    </el-col>
    <el-col v-if="!loading" :lg="9" class="shared-show__right">
      <div v-for="card in rights" :key="card">
        <display v-if="card === 'display'" :data="formData" :type="type" />
        <structure v-if="card === 'structure'" :data="formData" :type="type" />
        <cam-display
          v-if="card === 'cam_display'"
          :data="formData"
          :type="type"
        />
        <cam-type v-if="card === 'cam_type'" :data="formData" :type="type" />
        <dates v-if="card === 'dates'" :data="formData" :type="type" />
        <price v-if="card === 'price'" :data="formData" :type="type" />
        <days v-if="card === 'days'" :data="formData" :type="type" />
        <hours v-if="card === 'hours'" :data="formData" :type="type" />
        <address-info v-if="card === 'address'" :data="formData" :type="type" />
        <contact v-if="card === 'contact'" :data="formData" :type="type" />
        <category v-if="card === 'category'" :data="formData" :type="type" />
        <tags v-if="card === 'tags'" :data="formData" :type="type" />
        <youtube v-if="card === 'youtube'" :data="formData" :type="type" />
        <audios v-if="card === 'audios'" :data="formData" :type="type" />
        <pictures v-if="card === 'pictures'" :data="formData" :type="type" />
        <videos v-if="card === 'videos'" :data="formData" :type="type" />
        <country v-if="card === 'country'" :data="formData" :type="type" />
        <place v-if="card === 'place'" :data="formData" :type="type" />
        <tour v-if="card === 'tour'" :data="formData" :type="type" />
        <place-parent
          v-if="card === 'place_parent' && formData.parent_id"
          :data="formData"
          :type="type"
        />
        <parent v-if="card === 'parent'" :data="formData" :type="type" />
        <packages v-if="card === 'packages'" :data="formData" :type="type" />
        <status v-if="card === 'status'" :data="formData" :type="type" />
        <times v-if="card === 'times'" :data="formData" :type="type" />
        <date-of-birth v-if="card === 'date_of_birth'" :data="formData" :type="type" />
      </div>
    </el-col>
    <el-button
      class="btn-save"
      type="success"
      circle
      title="Lưu thông tin"
      @click="updateDetailInfo"
    >
      <i class="fas fa-save"></i>
    </el-button>
    <qrcode-popup
      :visible="qrVisible"
      :row="formData"
      @close="qrVisible = false"
    />
  </el-row>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters, mapActions } from 'vuex'

import SharedActions from './components/Actions.vue'

// INFO
import InputType from '../inputs/InputType'
import NumberType from '../inputs/NumberType'
import TextareaType from '../inputs/TextareaType'
import EditorType from '../inputs/EditorType'

// LEFT CARDS
import Services from '../cards/Services'
import Groups from '../cards/Groups'
import Artifacts from '../cards/Artifacts'
import ArtifactGroups from '../cards/ArtifactGroups'
import RoomTypes from '../cards/RoomTypes.vue'
import Schedule from '../cards/Schedule'
import ListFacility from '../cards/ListFacility'
import Foods from '../cards/Foods'
import FoodGroups from '../cards/FoodGroups'
import BackgroundGroup from '../cards/BackgroundGroup'
import Banner from '../cards/Banner'

// RIGHT CARDS
import Display from '../cards/Display'
import Structure from '../cards/Structure'
import CamDisplay from '../cards/CamDisplay'
import CamType from '../cards/CamType'
import Dates from '../cards/Dates'
import Price from '../cards/Price'
import Days from '../cards/Days'
import Hours from '../cards/Hours'
import AddressInfo from '../cards/Address'
import Contact from '../cards/Contact'
import Category from '../cards/Category'
import Tags from '../cards/Tags.vue'
import Youtube from '../cards/Youtube'
import Audios from '../cards/Audios'
import Pictures from '../cards/Pictures'
import Videos from '../cards/Videos'
import Packages from '../cards/Packages'
import Parent from '../cards/Parent'
import Place from '../cards/Place'
import Tour from '../cards/Tour'
import PlaceParent from '../cards/PlaceParent'
import Country from '../cards/Country'
import Status from '../cards/Status'
import Times from '../cards/Times.vue'
import DateOfBirth from '../cards/DateOfBirth.vue'

import QrcodePopup from '../popups/Qrcode.vue'

import { getPlaceById, updatePlace } from '@/services/place'

import TYPE from '@/data/type'

export default {
  name: 'SharedShow',
  components: {
    SharedActions,

    InputType,
    NumberType,
    TextareaType,
    EditorType,

    Services,
    Groups,
    Artifacts,
    ArtifactGroups,
    RoomTypes,
    ListFacility,
    Foods,
    FoodGroups,
    BackgroundGroup,
    Banner,

    Display,
    Structure,
    CamDisplay,
    CamType,
    Dates,
    Price,
    Days,
    Hours,
    AddressInfo,
    Contact,
    Category,
    Tags,
    Youtube,
    Audios,
    Pictures,
    Videos,
    Packages,
    Parent,
    Place,
    Tour,
    PlaceParent,
    Country,
    Schedule,
    Status,
    Times,
    DateOfBirth,

    QrcodePopup
  },
  props: {
    data: Object,
    type: String,
    actions: Array,
    inputs: Array,
    lefts: Array,
    rights: Array
  },
  data() {
    return {
      loading: true,
      formData: {
        name: ''
      },
      callingAPI: false,
      qrVisible: false
    }
  },
  computed: {
    ...mapGetters(['language']),

    placeType() {
      return TYPE.PLACES.find((p) => p.value === this.type)
    },

    formInputs() {
      return this.inputs.map((i) =>
        TYPE.INPUTS.find((input) => input.value === i)
      )
    },
    requiredInputs() {
      return this.formInputs.filter((i) => i.required)
    },
    params() {
      return {
        id: this.$route.params.id,
        locale: this.language,
        embedded: 'organization'
      }
    },
    payload() {
      let pay = {
        ...this.formData,
        locale: this.language
      }
      if (this.type === 'people') {
        pay = {
          ...pay,
          dob: this.formData.dob ? dayjs(this.formData.dob).format('YYYY-MM-DDT00:00:00') : null,
          dod: this.formData.dod ? dayjs(this.formData.dod).format('YYYY-MM-DDT00:00:00') : null
        }
      }
      return pay
    }
  },
  watch: {
    language: 'getDetailInfo'
  },
  beforeMount() {
    this.getDetailInfo()
  },
  methods: {
    ...mapActions('public', ['updateReloadConstant']),

    getDetailInfo() {
      this.loading = true
      getPlaceById(this.params).then((response) => {
        this.formData = JSON.parse(JSON.stringify({
          ...response,
          tag_ids: (response.tags || []).map((t) => t.id),
          dod: response.date_of_death ? dayjs(response.date_of_death.split('/').reverse().join('-')) : null,
          qr_code: response.qr_code || response.name,
          ordered: response.ordered || 1,
          location: response.location || {
            place_id: '',
            address: '',
            latitude: 0,
            longitude: 0
          }
        }))
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    handleValidate() {
      const invalids = []
      this.requiredInputs.forEach(i => {
        const v = this.$refs[i.ref][0].handleValidate()
        if (!v) {
          invalids.push(i)
        }
      })
      if (invalids.length > 0) {
        this.$notify.warning({
          title: 'Thông báo',
          message: 'Bạn chưa nhập thông tin bắt buộc..'
        })
        return false
      }
      return true
    },

    updateDetailInfo(showNotify = true) {
      if (this.handleValidate()) {
        this.callingAPI = showNotify
        updatePlace(this.payload).then(() => {
          if (['country', 'area'].includes(this.type)) {
            this.updateReloadConstant(dayjs().unix())
          }
          if (showNotify) {
            this.$notify.success({
              title: 'Thông báo',
              message: 'Cập nhật thông tin thành công.'
            })
          }
          this.callingAPI = false
        }).catch((error) => {
          this.callingAPI = false
          if (showNotify) {
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.shared-show {
  min-height: 800px;
  margin-bottom: 24px;
  &__left {
    padding-right: 6px;
  }
  &__right {
    padding-left: 6px;
  }
}
.btn-save {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 64px;
  height: 64px;
  font-size: 24px;
}
</style>
<style lang="scss">
.shared-show {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 5px;
  }
}
</style>
