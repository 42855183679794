<template>
  <el-form :model="data" label-position="top">
    <el-form-item :label="info.label">
      <el-input
        v-model="data[info.key]"
        :placeholder="info.placeholder"
        clearable
        type="textarea"
        :rows="5"
      ></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'TextareaType',
  props: {
    info: Object,
    data: Object
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss">
.el-textarea__inner {
  font-family: inherit;
}
</style>
