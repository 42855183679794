<template>
  <el-form :model="data" label-position="top">
    <el-form-item :label="info.label">
      <el-input
        v-model="number"
        :placeholder="info.placeholder"
        :min="info.min"
        clearable
        type="number"
        @input="handleInputNumber"
      ></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'NumberType',
  props: {
    info: Object,
    data: Object
  },
  data() {
    return {
      number: this.data[this.info.key] || 0
    }
  },
  methods: {
    handleInputNumber(number) {
      if (!isNaN(number)) {
        this.data[this.info.key] = Number(number)
      } else {
        this.number = 0
        this.data[this.info.key] = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
