<template>
  <el-dialog
    top="80px"
    width="700px"
    class="food-group-form-popup"
    :title="`${!foodGroup ? 'Tạo mới ' : 'Cập nhật thông tin' } nhóm món ăn`"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" class="pt-2 pr-2" :model="form" :rules="rules" label-position="top">
      <el-form-item label="Tên nhóm món ăn" prop="summary">
        <el-input v-model="form.summary" :disabled="callingAPI" placeholder="Nhập vào tên nhóm món ăn"></el-input>
      </el-form-item>
      <el-form-item label="Mô tả" prop="description">
        <el-input
          v-model="form.description"
          :rows="3"
          :disabled="callingAPI"
          type="textarea"
          placeholder="Nhập mô tả"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="foodGroupInfo && visible" lang>
        <pictures :data.sync="foodGroupInfo" type="dish_group" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button
        v-if="!foodGroupInfo"
        type="primary"
        :loading="callingAPI"
        @click="handleCreateFoodGroup"
      >Tạo mới</el-button>
      <el-button v-else type="primary" :loading="callingAPI" @click="handleUpdateFood">Cập nhật</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import Pictures from '../cards/Pictures.vue'
import { createFoodGroup, updateFoodGroup } from '@/services/food'

export default {
  name: 'CreateFoodGroup',
  components: {
    Pictures
  },
  props: {
    visible: Boolean,
    foodGroup: Object,
    object: Object
  },
  data() {
    return {
      callingAPI: false,
      form: {
        summary: '',
        description: ''
      },
      foodGroupInfo: null,
      rules: {
        summary: [
          { required: true, message: 'Tên nhóm món ăn không được để trống', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['language'])
  },
  watch: {
    visible: 'loadData'
  },
  methods: {
    loadData() {
      if (this.visible && this.foodGroup) {
        this.foodGroupInfo = this.foodGroup
        this.form = {
          summary: this.foodGroup.summary,
          description: this.foodGroup.description
        }
      } else {
        this.form = {
          summary: '',
          description: ''
        }
        this.foodGroupInfo = null
      }
    },
    handleCreateFoodGroup() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          createFoodGroup({
            ...this.form,
            locale: this.language,
            place_id: this.object.id
          }).then((response) => {
            this.callingAPI = false
            this.handleClose()
            this.$emit('reload')
            this.$notify.success({
              title: 'Thông báo',
              message: 'Tạo mới nhóm món ăn thành công'
            })
          }).catch((error) => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
            })
          })
        }
      })
    },
    handleUpdateFood() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          updateFoodGroup({
            ...this.foodGroupInfo,
            images: this.foodGroupInfo.images.map((i) => ({
              image_id: i.id,
              order: i.ordered
            })),
            ...this.form,
            locale: this.language
          }).then(() => {
            this.callingAPI = false
            this.$emit('reload')
            this.$notify.success({
              title: 'Thông báo',
              message: 'Cập nhật nhóm món ăn thành công'
            })
            this.handleClose()
          }).catch((error) => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data ? error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống' : 'Lỗi hệ thống, không thể cập nhật'
            })
          })
        }
      })
    },
    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.food-group-form-popup {
  .el-form {
    max-height: 550px;
    overflow: auto;
  }
}
</style>
