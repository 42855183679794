import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function listGroupsByObject(params, type) {
  const url = type === 'place' ? `${ENDPOINT.PLACE}/group/list` : ENDPOINT.GROUP
  return request({
    url: url,
    method: 'get',
    params
  })
}

export function updateGroup(data, type) {
  const url = type === 'place' ? `${ENDPOINT.PLACE}/group` : ENDPOINT.GROUP
  return request({
    url: url,
    method: 'patch',
    data
  })
}

export function createGroup(data, type) {
  const url = type === 'place' ? `${ENDPOINT.PLACE}/group` : ENDPOINT.GROUP
  return request({
    url: url,
    method: 'post',
    data
  })
}

export function deleteGroup(params, type) {
  const url = type === 'place' ? `${ENDPOINT.PLACE}/group` : ENDPOINT.GROUP
  return request({
    url: url,
    method: 'delete',
    params
  })
}

export function listGroupItems(params, type) {
  const url = type === 'place' ? `${ENDPOINT.PLACE}/group/item` : `${ENDPOINT.GROUP}/item`
  return request({
    url: url,
    method: 'get',
    params
  })
}

export function createItem(data, type) {
  const url = type === 'place' ? `${ENDPOINT.PLACE}/group/item` : `${ENDPOINT.GROUP}/item`
  return request({
    url: url,
    method: 'post',
    data
  })
}

export function updateItem(data, type) {
  const url = type === 'place' ? `${ENDPOINT.PLACE}/group/item` : `${ENDPOINT.GROUP}/item`
  return request({
    url: url,
    method: 'patch',
    data
  })
}

export function deleteItem(params, type) {
  const url = type === 'place' ? `${ENDPOINT.PLACE}/group/item` : ''
  return request({
    url: url,
    method: 'delete',
    params
  })
}
