<template>
  <el-dialog
    top="80px"
    width="900px"
    class="group-detail-popup"
    :title="`Danh sách món ăn của ${group.summary}`"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" class="pt-2 pr-2" :model="form" label-position="top">
      <el-row :gutter="12" type="end">
        <el-col :lg="5">
          <el-form-item>
            <el-select
              v-model="form.dishType"
              :disabled="callingAPI || loading"
              placeholder="Chọn loại"
              @change="getDishs"
            >
              <el-option
                v-for="t in dishTypes"
                :key="t.value"
                :value="t.value"
                :label="t.label"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="10">
          <el-form-item>
            <el-select
              v-model="form.dish"
              placeholder="Chọn món ăn"
              filterable
              remote
              :remote-method="searchDishs"
              :disabled="callingAPI || loading || !form.dishType"
              @change="getDishSizes"
            >
              <el-option
                v-for="dish in dishs"
                :key="dish.id"
                :value="dish.id"
                :label="dish.summary"
                :disabled="
                  !!groupDishs.find(
                    (d) => d.dish_size_group_id === dish.dish_size_group_id
                  )
                "
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6">
          <el-form-item>
            <el-select
              v-model="form.dishSize"
              placeholder="Chọn kích thước"
              :disabled="callingAPI || loading || !form.dish"
            >
              <el-option
                v-for="dish in dishSizes"
                :key="dish.id"
                :value="dish.id"
                :label="dish.dish_size | getDishSize"
                :disabled="
                  !!groupDishs.find(
                    (d) => d.dish_size_group_id === dish.dish_size_group_id
                  )
                "
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="3" align="right">
          <el-form-item>
            <el-button
              type="primary"
              :disabled="loading || callingAPI || !form.dish || !form.dishSize"
              @click="handleAddDish"
            >Thêm</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table :data="groupDishs">
      <el-table-column label="STT" type="index" width="50" />
      <el-table-column width="60">
        <template slot-scope="scope">
          <img
            :src="
              scope.row.avatar && scope.row.avatar.url
                ? scope.row.avatar.url
                : imageDefaultUrl
            "
            alt="href"
          />
        </template>
      </el-table-column>
      <el-table-column label="Tên món ăn" prop="summary"></el-table-column>
      <el-table-column label="Loại" prop="summary" width="100" align="center">
        <template slot-scope="scope">
          <el-tag type="success" effect="dark">{{
            scope.row.dish_type | getDishType
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="Kích thước"
        prop="dish_size"
        width="100"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.dish_size | getDishSize }}
        </template>
      </el-table-column>
      <el-table-column label="Giá (VND)" width="100">
        <template slot-scope="scope">
          <b>{{ scope.row.unit_price.amount | numberWithDelimiter }}</b>
        </template>
      </el-table-column>
      <el-table-column
        label="Thao tác"
        width="120"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            icon="el-icon-delete"
            circle
            type="danger"
            size="small"
            :disabled="callingAPI"
            @click="handleDeleteDish(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Đóng</el-button>
      <el-button
        type="primary"
        :loading="callingAPI"
        @click="handleUpdateGroup"
      >Cập nhật</el-button>
    </span>
  </el-dialog>
</template>

<script>
import DISH from '@/data/dish'
import { mapGetters } from 'vuex'
import { updateFoodGroup, getFoods } from '@/services/food'

export default {
  name: 'FoodGroupDetail',
  props: {
    visible: Boolean,
    group: Object,
    object: Object
  },
  data() {
    return {
      loading: false,
      callingAPI: false,
      dishs: [],
      searchText: '',
      form: {
        dishType: '',
        dish: '',
        dishSize: ''
      },
      dishTypes: DISH.TYPES,
      dishSizes: [],
      groupDishs: JSON.parse(JSON.stringify(this.group.items.map((i) => ({ ...i.dish })))),
      imageDefaultUrl: '/default-image.jpeg'
    }
  },
  computed: {
    ...mapGetters(['language']),
    filterDishsParams() {
      return {
        place_id: this.object.id,
        locale: this.language,
        dish_type: this.form.dishType,
        page: 1,
        per_page: 10,
        dish_size: 'M',
        search_text: this.searchText
      }
    },
    selectedDish() {
      return this.dishs.find((d) => d.id === this.form.dish)
    },
    dishSizesParams() {
      return {
        place_id: this.object.id,
        locale: this.language,
        page: 1,
        per_page: 10,
        dish_size_group_id: this.selectedDish ? this.selectedDish.dish_size_group_id : ''
      }
    },
    selectedDishSize() {
      return this.dishSizes.find((d) => d.id === this.form.dishSize)
    }
  },
  methods: {
    getDishs() {
      this.loading = true
      this.form.dish = ''
      getFoods(this.filterDishsParams).then((response) => {
        this.dishs = response.result
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    searchDishs(text) {
      this.searchText = text
      setTimeout(() => {
        this.getDishs()
      }, 100)
    },

    getDishSizes() {
      this.loading = true
      this.form.dishSize = ''
      getFoods(this.dishSizesParams).then((response) => {
        this.dishSizes = response.result
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    handleDeleteDish(food) {
      this.groupDishs = this.groupDishs.filter((f) => f.id !== food.id)
    },

    handleAddDish() {
      this.groupDishs.push(this.selectedDishSize)
      this.form = {
        dishType: this.form.dishType,
        dish: '',
        dishSize: ''
      }
    },

    handleUpdateGroup() {
      this.callingAPI = true
      updateFoodGroup({
        id: this.group.id,
        summary: this.group.summary,
        description: this.group.description,
        locale: this.language,
        place_id: this.group.place_id,
        items: this.groupDishs.map((item, index) => ({
          dish_id: item.id,
          order: this.groupDishs.length - index
        }))
      }).then(() => {
        this.callingAPI = false
        this.handleClose()
        this.$emit('reload')
        this.$notify.success({
          title: 'Thông báo',
          message: 'Cập nhật thành công'
        })
      }).catch((error) => {
        this.callingAPI = false
        this.$notify.error({
          title: 'Thông báo',
          message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
        })
      })
    },

    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.group-detail-popup {
  .el-form {
    max-height: 550px;
    overflow: auto;
  }
  img {
    width: 48px !important;
    height: 48px !important;
    object-fit: cover;
    border-radius: 50%;
  }
  a {
    color: #1174d4;
  }
}
</style>
