<template>
  <div class="food-groups one-component-detail-admin">
    <div class="title-component-admin">
      <el-row type="flex" align="middle" justify="space-between">
        <span>Danh sách nhóm món ăn ({{ total }})</span>
        <div>
          <el-input
            v-model="searchText"
            suffix-icon="el-icon-search"
            placeholder="Tìm kiếm theo tên"
            size="small"
            @input="handleGetFoodGroups"
          ></el-input>
          <el-button
            type="primary"
            size="small"
            title="Thêm"
            :disabled="loading"
            @click="handleOpenFormPopup(null)"
          >Thêm</el-button>
        </div>
      </el-row>
    </div>
    <div class="p-3">
      <el-table v-loading="loading" :data="foodGroups">
        <el-table-column label="STT" type="index" width="50"></el-table-column>
        <el-table-column width="60">
          <template slot-scope="scope">
            <img
              :src="
                scope.row.avatar && scope.row.avatar.url
                  ? scope.row.avatar.url
                  : imageDefaultUrl
              "
              alt="href"
            />
          </template>
        </el-table-column>
        <el-table-column label="Tên nhóm" prop="summary" min-width="100">
          <template slot-scope="scope">
            <a href="javascript:;" @click="handleOpenFormPopup(scope.row)">{{
              scope.row.summary
            }}</a>
          </template>
        </el-table-column>
        <el-table-column label="Mô tả" prop="description"></el-table-column>
        <el-table-column
          label="Ngày tạo"
          prop="created_at"
          width="100"
        ></el-table-column>
        <el-table-column
          label="Hiển thị"
          prop="summary"
          width="80"
          align="center"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.is_hidden"
              active-color="#13ce66"
              :active-value="false"
              :inactive-value="true"
              @change="handleUpdateFoodGroup(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="Thao tác"
          width="150"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-tooltip content="Chỉnh sửa thông tin" placement="top">
              <el-button
                icon="el-icon-edit"
                type="primary"
                circle
                size="small"
                @click="handleOpenFormPopup(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip content="Danh sách món ăn" placement="top">
              <el-button
                icon="el-icon-menu"
                type="primary"
                circle
                size="small"
                @click="handleOpenDetailPopup(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip content="Xóa nhóm" placement="top">
              <el-button
                icon="el-icon-delete"
                circle
                type="danger"
                size="small"
                @click="handledeleteFoodGroup(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-row type="flex" align="middle" justify="end" class="mt-2">
        <el-pagination
          :current-page.sync="page"
          :page-sizes="[10, 15, 20, 30]"
          :page-size.sync="limit"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleChangeSize"
          @current-change="handleChangePage"
        ></el-pagination>
      </el-row>
    </div>
    <food-group-popup
      :visible="formVisible"
      :food-group="selectedFoodGroup"
      :object="data"
      @close="formVisible = false"
      @reload="handleGetFoodGroups"
    />
    <food-group-detail-popup
      v-if="detailVisible"
      :visible="detailVisible"
      :group="selectedFoodGroup"
      :object="data"
      @close="detailVisible = false"
      @reload="handleGetFoodGroups"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getFoodGroups, deleteFoodGroup, updateFoodGroup } from '@/services/food'
import FoodGroupPopup from '../popups/FoodGroup.vue'
import FoodGroupDetailPopup from '../popups/FoodGroupDetail.vue'

export default {
  name: 'FoodGroups',
  components: {
    FoodGroupPopup,
    FoodGroupDetailPopup
  },
  props: {
    data: Object,
    type: String
  },
  data() {
    return {
      loading: true,
      foodGroups: [],
      formVisible: false,
      detailVisible: false,
      selectedFoodGroup: null,
      limit: 10,
      total: 0,
      page: 1,
      imageDefaultUrl: '/default-image.jpeg',
      searchText: ''
    }
  },
  computed: {
    ...mapGetters(['language', 'reloadFoodGroup']),
    params() {
      return {
        place_id: this.data.id,
        locale: this.language,
        search_text: '',
        page: this.page,
        per_page: this.limit,
        sort: '-order'
      }
    }
  },
  watch: {
    reloadFoodGroup: 'handleGetFoodGroups'
  },
  beforeMount() {
    this.handleGetFoodGroups()
  },
  methods: {
    handleGetFoodGroups() {
      this.loading = true
      getFoodGroups(this.params).then((response) => {
        this.foodGroups = response.result
        this.total = response.total
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    handleOpenFormPopup(group) {
      this.selectedFoodGroup = group
      this.formVisible = true
    },

    handleOpenDetailPopup(group) {
      this.selectedFoodGroup = group
      this.detailVisible = true
    },

    handleDeleteFoodGroup(group) {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        this.loading = true
        deleteFoodGroup({ id: group.id })
          .then(() => {
            this.foodGroups = this.foodGroups.filter((a) => a.id !== group.id)
            this.loading = false
          })
          .catch(() => {
            this.loading = false
            this.$notify.error({
              title: 'Thông báo',
              message: 'Không thể xóa nhóm món ăn này!'
            })
          })
      })
    },

    handleUpdateFoodGroup(foodGroup) {
      updateFoodGroup(foodGroup).then(() => {
        this.$notify.success({
          title: 'Thông báo',
          message: 'Cập nhật thành công'
        })
      }).catch((error) => {
        this.$notify.error({
          title: 'Thông báo',
          message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
        })
      })
    },

    handleChangePage(page) {
      this.page = page
      this.handleGetFoodGroups()
    },

    handleChangeSize(size) {
      this.limit = size
      this.page = 1
      this.handleGetFoodGroups()
    }
  }
}
</script>

<style lang="scss" scoped>
.food-groups {
  img {
    width: 48px !important;
    height: 48px !important;
    object-fit: cover;
    border-radius: 50%;
  }
  a {
    color: #1174d4;
  }
}
</style>
